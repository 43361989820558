/*
* Usage: https://smacss.com/book/type-base
**/
body {
  font-family: $lato;
}

h1{
  @include font-size(4);
  font-family: 'freelanduploaded_file';

  @include respond-to(xs) {
    @include font-size(4.8);
  }
}

h2 {
  @include font-size(2.2);
}

h3 {
  @include font-size(2);
}

h4 {
  @include font-size(1.6);
}

h5 {
  @include font-size(1.4);
}

h6 {
  @include font-size(1.1);
}



h2,
h3,
h4,
h5,
h6,
.btn {
  font-family: $lato; //semibold
  letter-spacing: .15em;
  text-transform: uppercase;
}

p {
  @include font-size(1.4);
  line-height: 24px;
  color: #8c8d8c;

}

.btn {
  @include font-size(1.2);
  color: #666766;
  border: 1px solid #d6d7d6;
  padding: 1em 1.25em;
  background-color: #fff;

  margin: 25px auto;
  @include respond-to(xs) {
    margin: auto;
  }

  form & {
    margin-top: 0;
  }

  .glyphicon {
    color: #cfc0a2;
  }

  &--gold {
    background-color: #cfc0a2;
    color: #fff;

    .glyphicon {
      color: #605c55;
    }
  }

  &--black {
    background-color: #000;
    color: #fff;

    &:active,
    &:hover,
    &:focus {
      color: #ccc;
    }
  }

}

.inverse {
  color: #fff;
}

p.contact-info {
  @include font-size(1.4);
  letter-spacing: .075em; 
  font-family: "Times New Roman", Times, serif;
  text-transform: uppercase;

  @include font-size(1.75);
  letter-spacing: .04em; 
  font-family: "disturbanceregular";

  a {
    @include font-size(1.75);
    text-transform: none;
  }

  &,
  a,
  .label {
    color: #8c8d8c;

    /*
    .header & {
       color: #333;
    }
    */
  }

  .label {
    font-weight: normal;
  }
}
