/*
* Usage: https://smacss.com/book/type-module
**/

@import "card";

.section {
  + .section {
    margin: 2.5rem 0;
  }

  &--shop {
    padding: 2.5rem 0;
    background-size: cover;
  }

  &--commemorative-necklace {
    h1 {
      margin-bottom: .25rem;
    }

    .btn {
      margin-top: .75rem;
    }
  }

  &--luxury-personified {
    .photo-credit {
      @include respond-to(xs) {
        right: 15px !important;
        color: #2c2b2b !important;
      }
    }
  }
}

.header {
  &__markerting-bar {
    @include font-size(1.1);

    background-color: #000;
    color: #fff;
    text-align: center;
    padding: .5em 0;
  }

  &__content {
    padding: 15px 0;
  }
}

.footer {
  &__cta {
    p {
      /*@include font-size(1.6);*/
      margin-bottom: 0;
      @include respond-to(sm) {
        line-height: 43px;
      }
    }

    .btn {
      margin: 25px auto 0;

      @include respond-to(sm) {
        margin: auto;
      }
    }

    @include respond-to(sm) {
      &__left,
      &__right {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &__coming-soon {
    margin-top: 35px;
  }
}

hr {
  border-top: 1px solid #aaa;
  width: 100px;

  &:after {
    content: "";
    width: 15px;
    height: 15px;
    border: 1px solid #ccc;
    background-color: #fff;
    position: absolute;
    border-radius: 50%;
    transform: translateX(-50%)translateY(-50%);
    top: auto;
    left: 50%;
  }
}


.modal  {
  .input-group {
    max-width: 400px;
    margin: 0 auto;
  }

  input {
   font-size: 1.2rem;
    color: #666766;
    border: 1px solid #d6d7d6;
    padding: 1em 1.25em;
    height: auto;
  }
}
