$lato: "Lato", sans-serif;

$font__main: "Lato", sans-serif;
$font__heading: 'Marcellus', serif;
$font__code: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font__pre: "Courier 10 Pitch", Courier, monospace;
$font__line-height-body: 1.5;
$font__line-height-pre: 1.6;

@mixin font__base-size() {

  @media (max-width: $break__xs) {
    @include font-size(1.5);
  }

  @media (min-width: $break__xs) {
    @include font-size(1.7);
  }

}




/* Generated by Font Squirrel (http://www.fontsquirrel.com) on February 16, 2016 */
@font-face {
  font-family: 'freelanduploaded_file';
  src: url('fonts/freeland-webfont.eot');
  src: url('fonts/freeland-webfont.eot?#iefix') format('embedded-opentype'),
       url('fonts/freeland-webfont.woff2') format('woff2'),
       url('fonts/freeland-webfont.woff') format('woff'),
       url('fonts/freeland-webfont.ttf') format('truetype'),
       url('fonts/freeland-webfont.svg#freelanduploaded_file') format('svg');
  font-weight: normal;
  font-style: normal;
}


/* Generated by Font Squirrel (http://www.fontsquirrel.com) on February 17, 2016 */
@font-face {
    font-family: 'disturbanceregular';
    src: url('fonts/disturbance-webfont.eot');
    src: url('fonts/disturbance-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/disturbance-webfont.woff2') format('woff2'),
         url('fonts/disturbance-webfont.woff') format('woff'),
         url('fonts/disturbance-webfont.ttf') format('truetype'),
         url('fonts/disturbance-webfont.svg#disturbanceregular') format('svg');
    font-weight: normal;
    font-style: normal;
}