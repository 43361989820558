/*--------------------------------------------------------------*/
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on February 16, 2016 */
@font-face {
  font-family: 'freelanduploaded_file';
  src: url("fonts/freeland-webfont.eot");
  src: url("fonts/freeland-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/freeland-webfont.woff2") format("woff2"), url("fonts/freeland-webfont.woff") format("woff"), url("fonts/freeland-webfont.ttf") format("truetype"), url("fonts/freeland-webfont.svg#freelanduploaded_file") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on February 17, 2016 */
@font-face {
  font-family: 'disturbanceregular';
  src: url("fonts/disturbance-webfont.eot");
  src: url("fonts/disturbance-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/disturbance-webfont.woff2") format("woff2"), url("fonts/disturbance-webfont.woff") format("woff"), url("fonts/disturbance-webfont.ttf") format("truetype"), url("fonts/disturbance-webfont.svg#disturbanceregular") format("svg");
  font-weight: normal;
  font-style: normal; }

/*--------------------------------------------------------------
1.0 Normalize
--------------------------------------------------------------*/
html {
  font-family: sans-serif;
  text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  margin: .67em 0;
  font-size: 2em; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  height: 0;
  box-sizing: content-box; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  color: inherit;
  font: inherit; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
  box-sizing: border-box; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  margin: 0 2px;
  padding: .35em .625em .75em;
  border: 1px solid #c0c0c0; }

legend {
  padding: 0;
  border: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/*--------------------------------------------------------------
2.0 Base
--------------------------------------------------------------*/
/*
* Usage: https://smacss.com/book/type-base
**/
body {
  font-family: "Lato", sans-serif; }

h1 {
  font-size: 4rem;
  font-family: 'freelanduploaded_file'; }
  @media (min-width: 768px) {
    h1 {
      font-size: 4.8rem; } }

h2 {
  font-size: 2.2rem; }

h3 {
  font-size: 2rem; }

h4 {
  font-size: 1.6rem; }

h5 {
  font-size: 1.4rem; }

h6 {
  font-size: 1.1rem; }

h2,
h3,
h4,
h5,
h6,
.btn {
  font-family: "Lato", sans-serif;
  letter-spacing: .15em;
  text-transform: uppercase; }

p {
  font-size: 1.4rem;
  line-height: 24px;
  color: #8c8d8c; }

.btn {
  font-size: 1.2rem;
  color: #666766;
  border: 1px solid #d6d7d6;
  padding: 1em 1.25em;
  background-color: #fff;
  margin: 25px auto; }
  @media (min-width: 768px) {
    .btn {
      margin: auto; } }
  form .btn {
    margin-top: 0; }
  .btn .glyphicon {
    color: #cfc0a2; }
  .btn--gold {
    background-color: #cfc0a2;
    color: #fff; }
    .btn--gold .glyphicon {
      color: #605c55; }
  .btn--black {
    background-color: #000;
    color: #fff; }
    .btn--black:active, .btn--black:hover, .btn--black:focus {
      color: #ccc; }

.inverse {
  color: #fff; }

p.contact-info {
  font-size: 1.4rem;
  letter-spacing: .075em;
  font-family: "Times New Roman", Times, serif;
  text-transform: uppercase;
  font-size: 1.75rem;
  letter-spacing: .04em;
  font-family: "disturbanceregular"; }
  p.contact-info a {
    font-size: 1.75rem;
    text-transform: none; }
  p.contact-info,
  p.contact-info a,
  p.contact-info .label {
    color: #8c8d8c;
    /*
    .header & {
       color: #333;
    }
    */ }
  p.contact-info .label {
    font-weight: normal; }

/*--------------------------------------------------------------
3.0 Accessibility
--------------------------------------------------------------*/
/*
* Usage: https://smacss.com/book/type-module
**/
.card {
  background-color: #f8f7f5;
  margin-right: -15px;
  margin-left: -15px;
  padding: 2.5rem 2.5rem; }
  @media (min-width: 768px) {
    .card {
      margin-right: 0;
      margin-left: 0;
      padding: 2.5rem 7.5rem; } }
  .card--container {
    padding-left: 30px;
    padding-right: 30px; }
    .card--container .row-cards {
      margin-right: -5px;
      margin-left: -5px; }
      .card--container .row-cards > div[class^="col-"] {
        margin-top: 15px;
        margin-bottom: 15px;
        padding-right: 5px;
        padding-left: 5px;
        padding-top: 5px;
        padding-bottom: 5px; }
        @media (min-width: 768px) {
          .card--container .row-cards > div[class^="col-"] {
            margin-top: 0;
            margin-bottom: 0; } }
        .card--container .row-cards > div[class^="col-"] .card {
          position: relative;
          height: 250px;
          overflow: hidden; }
          @media (min-width: 768px) {
            .card--container .row-cards > div[class^="col-"] .card--shop {
              height: 400px; } }
          @media (min-width: 992px) {
            .card--container .row-cards > div[class^="col-"] .card--shop {
              height: 350px; } }
        .card--container .row-cards > div[class^="col-"] .card-container {
          padding-right: 2.5rem;
          padding-left: 2.5rem;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          transform: translateY(50%); }
        .card--container .row-cards > div[class^="col-"] .card-content {
          transform: translateY(-50%); }
          .card--container .row-cards > div[class^="col-"] .card-content h2,
          .card--container .row-cards > div[class^="col-"] .card-content h5 {
            margin-top: 0; }
        @media (min-width: 768px) {
          .card--container .row-cards > div[class^="col-"] .btn--pin {
            margin-left: -5rem; } }
  .card--container .card--dashed:after {
    left: 5px;
    right: 5px; }
  .card .card-content:after {
    content: '';
    display: block;
    clear: both; }
  @media (min-width: 768px) {
    .card .card-content--left, .card .card-content--right {
      width: 50%;
      float: left;
      padding: 15px; } }
  .card--commemorative-necklace {
    background-color: #fff;
    background-size: 100% 100%;
    max-width: 700px;
    margin-right: -15px;
    margin-left: -15px; }
    @media (min-width: 768px) {
      .card--commemorative-necklace {
        margin: 0 auto;
        padding: 4rem 7.5rem;
        background-image: url(img/bg-card-commemorative-necklace.png); } }
  .card--shop {
    padding: 2.5rem 5rem; }
    .card--shop__tool {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(4rem) translateY(-2.5rem);
      z-index: 10; }
      @media (min-width: 992px) {
        .card--shop__tool {
          display: block; } }
    .card--shop__content {
      /*max-width: 300px;*/
      /*margin: 10px auto 25px;*/ }
    .card--shop .card-content--left .img {
      margin-bottom: 30px; }
    @media (min-width: 768px) {
      .card--shop .card-content--left .img {
        position: absolute;
        margin-bottom: 0;
        top: 0;
        left: 5%;
        width: 45%;
        height: 100%;
        transform: translateY(50%); }
        .card--shop .card-content--left .img img {
          transform: translateY(-50%); } }
  .card--bg {
    background-color: #ccc;
    background-size: cover;
    background-position: center center;
    min-height: 200px; }
  .card--rose-gold {
    background-color: #f0edec; }
    .card--rose-gold img {
      padding-top: 50px; }
  .card--dashed {
    background-color: #fff; }
    .card--dashed:after {
      content: "";
      position: absolute;
      left: 5px;
      right: 5px;
      top: 5px;
      bottom: 5px;
      z-index: 1;
      border: 2px dotted #9fa8a6; }
      @media (min-width: 768px) {
        .card--dashed:after {
          left: 20px;
          right: 20px; } }
    .card--dashed .card-container {
      z-index: 10; }
  .card .cabriolet-logo,
  .card .photo-credit {
    font-size: 1rem;
    text-align: right;
    color: #eee;
    position: absolute;
    right: 0;
    bottom: 0;
    /*margin: 10px 25px;*/
    margin: 5px 10px;
    text-transform: uppercase; }
    .card .cabriolet-logo a,
    .card .photo-credit a {
      color: #eee; }
    @media (min-width: 768px) {
      .card .cabriolet-logo,
      .card .photo-credit {
        margin: 10px; } }
  .card--buy-tickets {
    background-color: #2c2b2b;
    padding: 2.5rem 2.5rem 12.5rem;
    background-image: url(img/bg-buy-tickets--xs.jpg); }
    .card--buy-tickets h2,
    .card--buy-tickets p {
      max-width: 50%; }
    @media (min-width: 768px) {
      .card--buy-tickets {
        padding: 2.5rem 2.5rem;
        background-image: url(img/bg-buy-tickets.jpg); }
        .card--buy-tickets h2,
        .card--buy-tickets p {
          max-width: none; } }
  .card .cabriolet-logo {
    margin-right: 0px;
    margin-bottom: 0;
    background-color: #000;
    padding: 10px; }
  .card .cabriolet-logo-xs {
    background: #000;
    margin-right: -15px;
    margin-left: -15px; }

.btn--pin {
  text-align: right; }
  @media (min-width: 768px) {
    .btn--pin.text-left {
      text-align: left; }
    .btn--pin.text-center {
      text-align: center; } }

.section + .section {
  margin: 2.5rem 0; }

.section--shop {
  padding: 2.5rem 0;
  background-size: cover; }

.section--commemorative-necklace h1 {
  margin-bottom: .25rem; }

.section--commemorative-necklace .btn {
  margin-top: .75rem; }

@media (min-width: 768px) {
  .section--luxury-personified .photo-credit {
    right: 15px !important;
    color: #2c2b2b !important; } }

.header__markerting-bar {
  font-size: 1.1rem;
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: .5em 0; }

.header__content {
  padding: 15px 0; }

.footer__cta p {
  /*@include font-size(1.6);*/
  margin-bottom: 0; }
  @media (min-width: 992px) {
    .footer__cta p {
      line-height: 43px; } }

.footer__cta .btn {
  margin: 25px auto 0; }
  @media (min-width: 992px) {
    .footer__cta .btn {
      margin: auto; } }

@media (min-width: 992px) {
  .footer__cta__left, .footer__cta__right {
    padding-left: 0;
    padding-right: 0; } }

.footer__coming-soon {
  margin-top: 35px; }

hr {
  border-top: 1px solid #aaa;
  width: 100px; }
  hr:after {
    content: "";
    width: 15px;
    height: 15px;
    border: 1px solid #ccc;
    background-color: #fff;
    position: absolute;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    top: auto;
    left: 50%; }

.modal .input-group {
  max-width: 400px;
  margin: 0 auto; }

.modal input {
  font-size: 1.2rem;
  color: #666766;
  border: 1px solid #d6d7d6;
  padding: 1em 1.25em;
  height: auto; }

/*--------------------------------------------------------------
4.0 Layout
--------------------------------------------------------------*/
/*
* Usage: https://smacss.com/book/type-layout
**/
.container {
  max-width: 970px; }

/*--------------------------------------------------------------
5.0 Misc
--------------------------------------------------------------*/
/*
* Usage: Utility classes that do not fit anywhere in particular into SMACSS (https://smacss.com/book/)
**/
/* Text meant only for screen readers. */
.screen-reader-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }
  .screen-reader-text:hover, .screen-reader-text:active, .screen-reader-text:focus {
    font-size: 0.875rem;
    display: block;
    top: 5px;
    left: 5px;
    width: auto;
    height: auto;
    padding: 15px 23px 14px;
    border-radius: 3px;
    background-color: #f1f1f1;
    color: #293743;
    font-weight: bold;
    line-height: normal;
    text-decoration: none;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    z-index: 100000;
    /* Above WP toolbar. */
    clip: auto !important; }

.clear:before,
.clear:after {
  content: "";
  display: table; }

.clear:after {
  clear: both; }

.alignleft {
  float: left; }

.alignright {
  float: right; }

.required {
  color: #f00; }

img {
  max-width: 100%;
  height: auto; }

/*--------------------------------------------------------------
6.0 Shaaaaaaaaaaaaame
--------------------------------------------------------------*/
/*
* Usage: Anything you aren't proud of, or hope/plan to fix later. Hopefully empty, but useful for identifying pain points when it's not.
**/
/*--------------------------------------------------------------
7.0 Overriding Bootstrap Styles
--------------------------------------------------------------*/
