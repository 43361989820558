

.card {
  background-color: #f8f7f5;

  margin-right: -15px;
  margin-left: -15px;
  padding: 2.5rem 2.5rem;

  @include respond-to(xs) {
    margin-right: 0;
    margin-left: 0;
    padding: 2.5rem 7.5rem;
  }

  &--container {
    padding-left: 30px;
    padding-right: 30px;

    .row-cards {


      margin-right: -5px;
      margin-left: -5px;


      > div[class^="col-"] {
        margin-top: 15px;
        margin-bottom: 15px;

        padding-right: 5px;
        padding-left: 5px;
        padding-top: 5px;
        padding-bottom: 5px;

        @include respond-to(xs) {
          margin-top: 0;
          margin-bottom: 0;
        }
      
        .card {
          position: relative;
          height: 250px;
          overflow: hidden;

          &--shop {

            @include respond-to(xs) {
              height: 400px;
            }

            @include respond-to(sm) {
              height: 350px;
            }
          }
        }

        .card-container {
          padding-right: 2.5rem;
          padding-left: 2.5rem;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          transform: translateY(50%);
        }

        .card-content {
          transform: translateY(-50%);

          h2,
          h5 {
            margin-top: 0;
          }
        }

        .btn--pin {
          @include respond-to(xs) {
            margin-left: -5rem;
          }
        }
      }
    }
  }


  &--container &--dashed {
    &:after{
      left: 5px;
      right: 5px;
    }
  }

  .card-content {

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    &--left,
    &--right {
      @include respond-to(xs) {
        width: 50%;
        float: left;
        padding: 15px;
      }
    }
  }

  &--commemorative-necklace {
    background-color: #fff;

    background-size: 100% 100%;
    max-width: 700px;
    margin-right: -15px;
    margin-left: -15px;

    @include respond-to(xs) {
      margin: 0 auto;
      padding: 4rem 7.5rem;
      background-image: url(img/bg-card-commemorative-necklace.png);
    }

  }

  &--shop {
    padding: 2.5rem 5rem;

    &__tool {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(4rem)translateY(-2.5rem);
      z-index: 10;

      @include respond-to(sm) {
        display: block;
      }
    }

    &__content {
      /*max-width: 300px;*/
      /*margin: 10px auto 25px;*/
    }

    .card-content--left .img {
      margin-bottom: 30px;
    }

    @include respond-to(xs) {

      .card-content--left .img {

        position: absolute;
        margin-bottom: 0;
        top: 0;
        left: 5%;
        width: 45%;
        height: 100%;
        transform: translateY(50%);

        img {
          transform: translateY(-50%);
        }

      }      
    }
  }

  &--bg {
    background-color: #ccc;
    background-size: cover;
    background-position: center center;
    min-height: 200px;
  }

  &--rose-gold {
    background-color: #f0edec;

    img {
      padding-top: 50px;
    }
  }

  &--dashed {
    background-color: #fff;

    &:after {
      content: "";
      position: absolute;
      left: 5px;
      right: 5px;
      top: 5px;
      bottom: 5px;
      z-index: 1;
      border: 2px dotted #9fa8a6;

      @include respond-to(xs) {
        left: 20px;
        right: 20px;
      }
    }

    .card-container {
      z-index: 10;
    }
  }

  .cabriolet-logo,
  .photo-credit {
    @include font-size(1);
    text-align: right;
    color: #eee;
    position: absolute;
    right: 0;
    bottom: 0;
    /*margin: 10px 25px;*/
    margin: 5px 10px;
    text-transform: uppercase;
    
    a {
      color: #eee;
    }

    @include respond-to(xs) {
      margin: 10px;
    }
  }

  &--buy-tickets {
    background-color: #2c2b2b;
    padding: 2.5rem 2.5rem 12.5rem;
    background-image: url(img/bg-buy-tickets--xs.jpg);

    h2,
    p {
      max-width: 50%;
    }

    @include respond-to(xs) {
      padding: 2.5rem 2.5rem;
      background-image: url(img/bg-buy-tickets.jpg);
      
      h2,
      p {
        max-width: none;
      }
    }
  }

  .cabriolet-logo {
    margin-right: 0px;
    margin-bottom: 0;
    background-color: #000;
    padding: 10px;
  }

  .cabriolet-logo-xs {
    background: #000;
    margin-right: -15px;
    margin-left: -15px;
  }
}

.btn--pin {
  text-align: right;

  @include respond-to(xs) {
    &.text-left {
      text-align: left;
    }

    &.text-center {
      text-align: center;
    }
  }
}



